@import "_libs.scss";
@import '../node_modules/ngx-bootstrap/datepicker/bs-datepicker.css';
@import "datepicker";
@import "src/app/core/components/blurhash-img/blurhash-style.scss";
@import 'swiper/swiper-bundle.min.css';

*, *::before, *::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: inherit;
  transition: 0.3s ease-in-out;
}

ul[class] {
  list-style: none;
}

img {
  display: block;
}

input, button {
  &:hover, &:focus, &:active {
    outline: none;
  }
}

input, button, textarea, select {
  font: inherit;
}

button {
  display: block;
}

button, a {
  cursor: pointer;
}

ul, ol {
  padding: 0;
}

body, h1, h2, h3, h4, h5, h6, p, ul, ol, li, figure, figcaption, blockquote, dl, dd {
  margin: 0;
}

html, body {
  width: 100%;
  max-width: 100%;
}

html {
  height: 100%;
}

body {
  min-height: 100vh;
  height: 100%;
  font-size: 15px;
  line-height: normal;
  color: #333;
}

.container {
  max-width: 1620px;
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;

  &_mt-auto {
    margin-top: auto;
  }
}

.main-wrap {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 100px);
  height: 100%;
  padding-bottom: 40px;
  background-color: #FFF;
}

h2 {
  margin-bottom: 15px;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
}

h3 {
  font-size: 18px;
  font-weight: 600;
}

.object-fit {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.toast-container.toast-bottom-center .ngx-toastr {
  width: max-content;
  max-width: 340px;
  @media screen and (max-width: 320px) {
    max-width: 300px;
  }
}

.responsive-img {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 140px;
  border-radius: 100%;
  overflow: hidden;
  user-select: none;
}

.form {
  &-group {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    &__centered-big {
      .form-label {
        text-align: center;
        color: var(--Black, #333);
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
      }

      input {
        text-align: center;
        font-size: 24px;
        font-weight: 400;

        &::placeholder {
          color: #BEBEBE;
          font-size: 24px;
          font-weight: 400;
        }
      }
    }
  }

  &-label {
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 10px;
    display: block;

    &__err-msg {
      color: #e60c0c;
      font-size: 1rem;
      margin-top: 0.75rem;
    }

    &_grey {
      color: #BEBEBE;
      margin-top: 10px;
    }
  }

  &-input {
    height: 54px;
    width: 100%;
    padding: 18px 32px 18px 20px;
    border-radius: 16px;
    border: 1px solid #BEBEBE;

    &.ng-invalid.ng-touched {
      border-color: #e60c0c;
    }

    &:focus {
      border-color: #33125b;
    }

    &::placeholder {
      color: #BEBEBE;
      font-size: 15px;
      font-weight: 400;
    }
  }

  &-textarea {
    height: 255px;
    padding: 18px 32px 18px 20px;
    resize: none;
  }
}

.btn {
  width: 100%;
  height: 38px;
  border-radius: 4px;
  border: 1px solid transparent;
  font-size: 1rem;
  font-weight: 500;
  transition: 0.3s ease-in-out;
  display: block;

  &-primary {
    background: #33125b;
    color: #ffffff;
    border-color: #33125b;
  }

  &-secondary {
    background: #ffffff;
    color: #33125b;
    border-color: #33125b;
  }

  &-link {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:hover {
    opacity: 0.5;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &-custom {
    width: 100%;
    height: 54px;
    border-radius: 16px;
    font-size: 15px;
    font-weight: 600;
    border: 1px solid transparent;
    background: transparent;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  &-none {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    display: block;
    height: fit-content;
    width: fit-content;
  }

  &-white {
    border-color: #333;
    color: #333;
  }

  &-orange {
    border-color: #1576E5;
    background: #1576E5;
    color: #FFFFFF;
  }

  &-red {
    border-color: #EC4242;
    color: #EC4242;
  }

  &-grey {
    border-color: #333;
    background: #333;
    color: #FFFFFF;
  }
}

.modal {
  &-title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 25px;
  }
}

.input-date {
  position: relative;

  &__svg {
    position: absolute;
    top: 15px;
    right: 15px;
  }
}



.fade-in {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.fade-in-visible {
  opacity: 1;
}

.fade-out {
  opacity: 1;
  transition: opacity 0.5s ease-out;
}

.fade-out-hidden {
  opacity: 0;
}

.canvas{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
